"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcurementCreateScreen = void 0;
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var repository_1 = require("@codebuild/sprinkles/libs/repository/repository");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var datepicker_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/datepicker.form-control-type");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var datepicker_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/datepicker/datepicker");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var textarea_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/textarea-input");
var lodash_1 = require("lodash");
var moment_1 = require("moment");
var React = require("react");
var sticky_container_1 = require("../../../common/components/sticky-container");
var form_1 = require("../../../components/form/form");
var handle_toasts_1 = require("../../../components/libs/handle.toasts");
var message_box_1 = require("../../../components/libs/message-box/message-box");
require("../procurement-screen.scss");
var procurement_products_table_component_1 = require("./components/procurement-products-table.component");
var ProcurementCreateScreen = (function (_super) {
    __extends(ProcurementCreateScreen, _super);
    function ProcurementCreateScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                supplier: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'supplier',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('procurement.create.form.supplier.label'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    optionsEndpoint: '/b2b/supplier-partner?sort[name]=1&_sort[name]=1&limit=5000',
                    optionsMap: function (response) {
                        var _a;
                        return (_a = response === null || response === void 0 ? void 0 : response.items) === null || _a === void 0 ? void 0 : _a.map(function (o) { return ({
                            name: "".concat(o.name).concat(!!o.brandName ? " (".concat(o.brandName, ")") : ''),
                            value: o._id,
                            key: o._id
                        }); });
                    },
                    loadOptionsAfterMount: true,
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                comment: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'comment',
                    label: (0, trans_1.trans)('procurement.create.form.comment.label')
                }),
                plannedDeliveryDate: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'plannedDeliveryDate',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('procurement.create.form.comment.planned-delivery-date'),
                        " ",
                        React.createElement("i", { className: 'color--error-6' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                confirmedDeliveryDate: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'confirmedDeliveryDate',
                    label: (0, trans_1.trans)('procurement.create.form.comment.confirmed-delivery-date'),
                }),
            }
        });
        _this.state = {
            loading: false,
            supplierList: [],
            isAddProductsDisabled: true,
            isSupplierSelectDisabled: false,
            validationError: '',
            saveError: '',
        };
        return _this;
    }
    ProcurementCreateScreen.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.fetchSupplierList()];
                    case 1:
                        _a.sent();
                        this.$subscriptions.push(this.form.fields.supplier.$value.subscribe(function (value) {
                            var _a;
                            var supplier = _this.state.supplierList.find(function (supp) { return supp._id === value; });
                            var commentToDisplay = (_a = supplier === null || supplier === void 0 ? void 0 : supplier.comment) !== null && _a !== void 0 ? _a : '';
                            _this.setState({ commentToDisplay: commentToDisplay, isAddProductsDisabled: !value });
                        }), this.form.$submitSuccess.subscribe(function (value) { return _this.onFormSubmit(value); }));
                        return [2];
                }
            });
        });
    };
    ProcurementCreateScreen.prototype.fetchSupplierList = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4, this.repository.get('/b2b/supplier-partner?sort[name]=1&_sort[name]=1&limit=5000')];
                    case 1:
                        response = _b.sent();
                        this.setState({ supplierList: (_a = response === null || response === void 0 ? void 0 : response.items) !== null && _a !== void 0 ? _a : [] });
                        return [3, 3];
                    case 2:
                        e_1 = _b.sent();
                        console.log({ fetchSupplierList: e_1 });
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    ProcurementCreateScreen.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (!(0, lodash_1.isEqual)(prevState.planProducts, this.state.planProducts)) {
        }
    };
    ProcurementCreateScreen.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: 'ProcurementEditScreen row w-100' },
            React.createElement("div", { className: 'col procurement-content-wrapper' },
                React.createElement("div", { className: 'mb-2' },
                    React.createElement(button_1.Button, { className: 'button-neutral-link button-size-normal mb-1', title: 'Vissza', iconLeft: 'fas fa-arrow-left', onClick: function () { return router_provider_1.RouterProvider.goTo('/procurement'); } })),
                React.createElement("div", { className: 'mb-4' },
                    React.createElement("h2", null, (0, trans_1.trans)('procurement.create.title'))),
                React.createElement("div", { className: 'row' },
                    React.createElement(form_1.Form, { className: 'w-100' },
                        React.createElement("div", { className: 'col-24 row' },
                            React.createElement("div", { className: 'col-8 mb-4' },
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.supplier, type: select_input_form_control_type_1.SelectInputFormControlType },
                                    React.createElement(select_input_1.SelectInput, { disabled: this.props.disabled || this.state.isSupplierSelectDisabled, unclearable: false, multiple: false, searchable: true, className: 'select-input-basic' }))),
                            this.state.commentToDisplay && React.createElement("div", { className: 'mb-4 col-8 display-flex flex-column justify-content-center' },
                                React.createElement("p", { className: 'typo-small' }, "Besz\u00E1ll\u00EDt\u00F3 partner megjegyz\u00E9s"),
                                React.createElement("p", { className: 'fw-700 ml-2' }, this.state.commentToDisplay))),
                        React.createElement("div", { className: 'display-flex' },
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.plannedDeliveryDate, type: datepicker_form_control_type_1.DatepickerFormControlType },
                                React.createElement(datepicker_1.Datepicker, { readOnly: false, type: 'date', className: 'datepicker-basic col-8', locale: 'hu', settings: { enabledDateFrom: (0, moment_1.utc)().subtract(1, 'days').format('YYYY/MM/DD') } })),
                            React.createElement(form_control_1.FormControl, { field: this.form.fields.confirmedDeliveryDate, type: datepicker_form_control_type_1.DatepickerFormControlType },
                                React.createElement(datepicker_1.Datepicker, { readOnly: false, type: 'date', className: 'datepicker-basic col-8', locale: 'hu', settings: { enabledDateFrom: (0, moment_1.utc)().subtract(1, 'days').format('YYYY/MM/DD') } }))),
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.comment, type: text_input_form_control_type_1.TextInputFormControlType },
                            React.createElement(textarea_input_1.TextareaInput, { disabled: this.props.disabled, rows: 6, className: 'textarea-input-basic col-14' })),
                        React.createElement("div", { className: 'col display-flex flex-column align-items-center' },
                            React.createElement(procurement_products_table_component_1.ProcurementProductsTableComponent, { ref: function (ref) { return _this.createInputFormRef$ = ref; }, procurementAction: 'create', isAddProductsDisabled: this.state.isAddProductsDisabled, setSupplierSelectDisabled: function (bool) { return _this.setState({ isSupplierSelectDisabled: bool }); }, supplier: this.form.fields.supplier.getValue() }),
                            this.state.validationError && React.createElement(message_box_1.MessageBox, { type: 'error', message: React.createElement("div", { className: 'display-flex align-items-center' },
                                    React.createElement("i", { className: 'far fa-exclamation-circle mr-2' }),
                                    React.createElement("p", { className: 'fw-600' }, (0, trans_1.trans)(this.state.validationError))) }))))),
            React.createElement("div", { className: 'col procurement-save-box-wrapper' }, this.renderSaveBox()));
    };
    ProcurementCreateScreen.prototype.renderSaveBox = function () {
        var _this = this;
        return React.createElement(sticky_container_1.StickyContainer, { topSpacing: 32, className: 'w-100' },
            React.createElement("div", { className: 'procurement-save-box mb-6' },
                React.createElement("h5", { className: 'mb-6' }, "L\u00C9TREHOZ\u00C1S"),
                React.createElement(button_1.Button, { title: this.state.loading ? React.createElement("i", { className: 'far fa-spinner-third fa-spin' }) : 'Indítás', iconLeft: this.state.loading ? '' : 'fal fa-plus', className: 'button-primary-normal button-size-normal', disabled: false, onClick: function () { return _this.form.submit(); } })),
            this.state.saveError && React.createElement(message_box_1.MessageBox, { type: 'error', message: React.createElement("div", { className: 'display-flex flex-column align-items-start' }, this.state.errorMessage) }));
    };
    ProcurementCreateScreen.prototype.getData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var valid;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.form.validate()];
                    case 1:
                        valid = _a.sent();
                        if (!valid) {
                            return [2, { error: true }];
                        }
                        return [2, this.form.toJSON()];
                }
            });
        });
    };
    ProcurementCreateScreen.prototype.onFormSubmit = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            var valid, products, data, response, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        this.setState({ loading: true });
                        return [4, this.form.validate()];
                    case 1:
                        valid = _a.sent();
                        if (!valid) {
                            throw new Error('MissingData');
                        }
                        return [4, this.createInputFormRef$.getData()];
                    case 2:
                        products = _a.sent();
                        if (!!products.error) {
                            return [2, this.setState({ loading: false, validationError: 'procurement.error.form.not.valid' })];
                        }
                        if (!products.plan.length) {
                            return [2, this.setState({ loading: false, validationError: 'procurement.error.form.missing.products' })];
                        }
                        data = {
                            supplier: value === null || value === void 0 ? void 0 : value.supplier,
                            comment: value === null || value === void 0 ? void 0 : value.comment,
                            plan: products.plan,
                            plannedDeliveryDate: value === null || value === void 0 ? void 0 : value.plannedDeliveryDate,
                            confirmedDeliveryDate: value === null || value === void 0 ? void 0 : value.confirmedDeliveryDate,
                        };
                        return [4, this.repository.post('/b2b/procurement', { data: data })];
                    case 3:
                        response = _a.sent();
                        this.setState({ loading: false });
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('procurement.create.success.toast'));
                        return [2, router_provider_1.RouterProvider.goTo("/procurement/".concat(response._id))];
                    case 4:
                        e_2 = _a.sent();
                        console.log({ GOT_ERROR: e_2 });
                        this.setState(function (prev) {
                            var _a;
                            if (((_a = e_2 === null || e_2 === void 0 ? void 0 : e_2.message) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes('email'))) {
                                return __assign(__assign({}, prev), { errorMessage: (0, trans_1.trans)('procurement.create.save.email.error') });
                            }
                            return __assign(__assign({}, prev), { errorMessage: (0, trans_1.trans)('procurement.create.save.error') });
                        });
                        this.setState({ loading: false, saveError: true });
                        return [3, 5];
                    case 5: return [2];
                }
            });
        });
    };
    return ProcurementCreateScreen;
}(abstract_component_1.AbstractComponent));
exports.ProcurementCreateScreen = ProcurementCreateScreen;
