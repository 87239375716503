"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.DashboardScreen = void 0;
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var React = require("react");
var sold_per_category_amount_chart_1 = require("../components/charts/sold-per-category-amount-chart");
var sold_per_category_price_chart_1 = require("../components/charts/sold-per-category-price-chart");
var top_order_city_table_1 = require("../components/charts/top-order-city-table");
var top_sold_products_table_1 = require("../components/charts/top-sold-products-table");
var total_sold_chart_1 = require("../components/charts/total-sold-chart");
var DashboardScreen = (function (_super) {
    __extends(DashboardScreen, _super);
    function DashboardScreen() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DashboardScreen.prototype.render = function () {
        return React.createElement("div", null,
            React.createElement("div", { className: 'display-flex align-items-center mb-8' },
                React.createElement("h2", { className: 'mr-4' }, "DASHBOARD")),
            React.createElement("div", { className: 'row flex-column flex-lg-row' },
                React.createElement("div", { className: 'col' },
                    React.createElement(total_sold_chart_1.TotalSoldChart, null))),
            React.createElement("div", { className: 'row flex-column flex-lg-row' },
                React.createElement("div", { className: 'col col-lg-12' },
                    React.createElement(sold_per_category_amount_chart_1.SoldPerCategoryAmountChart, null),
                    React.createElement(sold_per_category_price_chart_1.SoldPerCategoryPriceChart, null)),
                React.createElement("div", { className: 'col col-lg-12' },
                    React.createElement(top_sold_products_table_1.TopSoldProductsTable, null),
                    React.createElement(top_order_city_table_1.TopOrderCityTable, null))));
    };
    return DashboardScreen;
}(abstract_component_1.AbstractComponent));
exports.DashboardScreen = DashboardScreen;
