"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
module.exports = {
    'b2b-supplier-pricing.list.main.title': 'Beszállítói árazás',
    'b2b-supplier-pricing.filter.form.label.supplier': 'Beszállító partner',
    'b2b-supplier-pricing.filter.form.label.date': 'Dátum',
    'b2b-supplier-pricing.filter.form.label.category': 'Kategória',
    'b2b-supplier-pricing.filter.form.label.manufacturer': 'Pince',
    'b2b-supplier-pricing.toast.success.supplier': 'Sikeres beszállító módosítás',
    'b2b-supplier-pricing.toast.error.supplier': 'Sikertelen beszállító módosítás',
    'b2b-supplier-pricing.table.header.checkbox': '',
    'b2b-supplier-pricing.table.header.category': 'kategória',
    'b2b-supplier-pricing.table.header.product': 'termék',
    'b2b-supplier-pricing.table.header.price': 'beszállítói ár',
    'b2b-supplier-pricing.table.header.edit': '',
    'b2b-supplier-pricing.modal.button.open': 'Szerkesztés',
    'b2b-supplier-pricing.pricing.show.modal.title': 'Ár módosítása',
    'b2b-supplier-pricing.pricing.show.modal.supplier': 'Beszállító partner',
    'b2b-supplier-pricing.edit.modal.table.header.start': 'kezdés',
    'b2b-supplier-pricing.edit.modal.table.header.end': 'vége',
    'b2b-supplier-pricing.edit.modal.table.header.status': 'státusz',
    'b2b-supplier-pricing.edit.modal.table.header.price': 'ár',
    'b2b-supplier-pricing.edit.modal.table.header.edit': React.createElement("i", { className: 'fa-solid fa-pencil' }),
    'b2b-supplier-pricing.edit.modal.table.header.delete': React.createElement("i", { className: 'fa-solid fa-trash-can' }),
    'b2b-supplier-pricing.pricing.edit.modal.title': 'Ár megadása',
    'b2b-supplier-pricing.error.missing.required': 'Kötelző adatok hiányoznak',
    'b2b-supplier-pricing.error.Unauthorized': 'Nincs jogosultságod a művelet végrehajtásához',
    'b2b-supplier-pricing.error.MissingSupplierId': 'Hiányzó beszállító azonosító',
    'b2b-supplier-pricing.error.MissingProductId': 'Hiányzó termék azonosító',
    'b2b-supplier-pricing.error.MissingFromDate': 'Hiányzó kezdő dátum',
    'b2b-supplier-pricing.error.MissingValue': 'Hiányzó ár',
    'b2b-supplier-pricing.error.UnknownSupplierPartner': 'Ismeretlen beszállító partner',
    'b2b-supplier-pricing.error.UnknownProduct': 'Ismeretlen termék',
    'b2b-supplier-pricing.error.MissingSupplierPartnerPriceDocument': 'Hiányzó ár adatok',
    'b2b-supplier-pricing.error.FromMustBeInFuture': 'Kezdeti dátumnak a jövőben kell lennie',
    'b2b-supplier-pricing.error.ToMustBeAfterFrom': 'A vége dátumnak a kezdeti dátum után kell lennie',
};
