"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BundleUpdateScreen = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var datepicker_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/datepicker.form-control-type");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var datepicker_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/datepicker/datepicker");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var textarea_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/textarea-input");
var moment = require("moment");
var React = require("react");
var form_1 = require("../../../components/form/form");
var image_uploader_1 = require("../../../components/image-uploader/image-uploader");
var handle_toasts_1 = require("../../../components/libs/handle.toasts");
var message_box_1 = require("../../../components/libs/message-box/message-box");
var add_product_modal_1 = require("./add-product.modal");
require("./bundle-create-update.scss");
var bundle_product_list_1 = require("./bundle-product.list");
var BundleUpdateScreen = (function (_super) {
    __extends(BundleUpdateScreen, _super);
    function BundleUpdateScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                title_hu: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'title_hu',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('bundle.form.title_hu'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                title_en: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'title_en',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('bundle.form.title_en'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                description_hu: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'description_hu',
                    label: (0, trans_1.trans)('bundle.form.description_hu'),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                description_en: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'description_en',
                    label: (0, trans_1.trans)('bundle.form.description_en'),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                categories: new field_1.Field({
                    placeholder: '',
                    value: [],
                    name: 'categories',
                    optionsMap: (function (response) { return response.items.map(function (i) { var _a; return ({ name: (_a = i.name) === null || _a === void 0 ? void 0 : _a.hu, key: i._id, value: i._id }); }); }),
                    optionsEndpoint: '/admin/b2c/category',
                    loadOptionsAfterMount: true,
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('bundle.form.categories'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                validTo: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'validTo',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('bundle.form.validTo'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                })
            }
        });
        _this.state = {
            error: null,
            item: null,
            categories: []
        };
        return _this;
    }
    BundleUpdateScreen.prototype.componentDidMount = function () {
        var _this = this;
        this.fetch();
        this.form.$submitSuccess.subscribe(function () { return _this.updateForYou(); });
        this.form.fields.categories.$value.subscribe(function (v) { return _this.setState({ categories: v }); });
    };
    BundleUpdateScreen.prototype.fetch = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        return __awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return __generator(this, function (_p) {
                switch (_p.label) {
                    case 0:
                        _p.trys.push([0, 2, , 3]);
                        return [4, this.repository.get("/admin/bundle/".concat((_b = (_a = this.props.route) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.itemId))];
                    case 1:
                        response = _p.sent();
                        this.setState({ item: response });
                        this.form.fields.title_hu.setValue((_d = (_c = this.state.item) === null || _c === void 0 ? void 0 : _c.title) === null || _d === void 0 ? void 0 : _d.hu);
                        this.form.fields.title_en.setValue((_f = (_e = this.state.item) === null || _e === void 0 ? void 0 : _e.title) === null || _f === void 0 ? void 0 : _f.en);
                        this.form.fields.description_hu.setValue((_h = (_g = this.state.item) === null || _g === void 0 ? void 0 : _g.description) === null || _h === void 0 ? void 0 : _h.hu);
                        this.form.fields.description_en.setValue((_k = (_j = this.state.item) === null || _j === void 0 ? void 0 : _j.description) === null || _k === void 0 ? void 0 : _k.en);
                        this.form.fields.validTo.setValue(moment((_l = this.state.item) === null || _l === void 0 ? void 0 : _l.validTo).format('YYYY.MM.DD.'));
                        this.form.fields.categories.setValue((_m = this.state.item) === null || _m === void 0 ? void 0 : _m.categories);
                        this.setState({ categories: (_o = this.state.item) === null || _o === void 0 ? void 0 : _o.categories });
                        return [3, 3];
                    case 2:
                        e_1 = _p.sent();
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    BundleUpdateScreen.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d;
        return React.createElement("div", { className: 'BundleCreateScreen  justify-content-center w-100' },
            React.createElement("h2", { className: 'text-center mb-6' }, (0, trans_1.trans)('bundle.update.screen.title')),
            this.renderForYouForm(),
            React.createElement("div", null,
                React.createElement(add_product_modal_1.AddProductModal, { categories: this.state.categories, forYouId: (_a = this.state.item) === null || _a === void 0 ? void 0 : _a._id, onSubmitSuccess: function () { return _this.fetch(); } }),
                React.createElement(bundle_product_list_1.BundleProductList, { onChange: function () { return _this.fetch(); }, forYouId: (_b = this.state.item) === null || _b === void 0 ? void 0 : _b._id, products: (_d = (_c = this.state) === null || _c === void 0 ? void 0 : _c.item) === null || _d === void 0 ? void 0 : _d.products })));
    };
    BundleUpdateScreen.prototype.renderForYouForm = function () {
        var _this = this;
        var _a;
        return React.createElement("div", { className: 'ForYouCreateForm row w-100 justify-content-center' },
            React.createElement("div", { className: "col-lg-8 col-md-12 col-24" },
                React.createElement(form_1.Form, null,
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.title_hu, type: text_input_form_control_type_1.TextInputFormControlType },
                        React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.title_en, type: text_input_form_control_type_1.TextInputFormControlType },
                        React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                    React.createElement("div", null, this.renderImageUploader()),
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.description_hu, type: text_input_form_control_type_1.TextInputFormControlType },
                        React.createElement(textarea_input_1.TextareaInput, { className: 'textarea-input-basic' })),
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.description_en, type: text_input_form_control_type_1.TextInputFormControlType },
                        React.createElement(textarea_input_1.TextareaInput, { className: 'textarea-input-basic' })),
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.categories, type: select_input_form_control_type_1.SelectInputFormControlType },
                        React.createElement(select_input_1.SelectInput, { searchable: true, multiple: true, className: 'select-input-basic' })),
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.validTo, type: datepicker_form_control_type_1.DatepickerFormControlType },
                        React.createElement(datepicker_1.Datepicker, { className: 'datepicker-basic', disabled: false, type: 'date', locale: 'hu' }))),
                React.createElement("div", { className: 'display-flex flex-row justify-content-end' },
                    React.createElement(button_1.Button, { className: 'button-primary-outline button-size-normal mr-2', title: (0, trans_1.trans)('general.button.title.cancel'), onClick: function () { return _this.cancelUpdate(); } }),
                    React.createElement(button_1.Button, { className: 'button-primary-normal button-size-normal ml-2 ', title: (0, trans_1.trans)('bundle.update.button.title'), onClick: function () { return _this.form.submit(); } })),
                React.createElement("div", { className: 'py-6' }, !!this.state.error &&
                    React.createElement(message_box_1.MessageBox, { type: 'error', message: (0, trans_1.trans)("bundle.update.".concat((0, trans_1.trans)((_a = this.state.error) === null || _a === void 0 ? void 0 : _a.message))) }))));
    };
    BundleUpdateScreen.prototype.renderImageUploader = function () {
        var _this = this;
        var _a;
        return React.createElement("div", null,
            React.createElement("div", { className: 'mb-4' },
                React.createElement(image_uploader_1.ImageUploader, { ref: function (ref) { return _this.imageUploaderRef$ = ref; }, value: (_a = this.state.item) === null || _a === void 0 ? void 0 : _a.image, targetVariant: "default", width: 368, heigth: 368 })));
    };
    BundleUpdateScreen.prototype.cancelUpdate = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.form.updateFields({})];
                    case 1:
                        _a.sent();
                        return [4, this.form.clear()];
                    case 2:
                        _a.sent();
                        router_provider_1.RouterProvider.goTo('/bundle');
                        return [2];
                }
            });
        });
    };
    BundleUpdateScreen.prototype.updateForYou = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var data, image, parsedData, e_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        data = this.form.toJSON();
                        image = this.imageUploaderRef$.getValue();
                        parsedData = __assign(__assign({}, data), { image: image, title: { hu: data === null || data === void 0 ? void 0 : data.title_hu, en: data.title_en }, description: { hu: data === null || data === void 0 ? void 0 : data.description_hu, en: data.description_en }, validTot: moment.utc(data.validTo) });
                        return [4, this.repository.put("/admin/bundle/".concat((_a = this.state.item) === null || _a === void 0 ? void 0 : _a._id), { data: parsedData })];
                    case 1:
                        _b.sent();
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('bundle.update.success.toast'));
                        router_provider_1.RouterProvider.goTo('/bundle');
                        return [3, 3];
                    case 2:
                        e_2 = _b.sent();
                        this.setState({ error: e_2 });
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    return BundleUpdateScreen;
}(abstract_component_1.AbstractComponent));
exports.BundleUpdateScreen = BundleUpdateScreen;
