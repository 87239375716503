"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerPartnerReportScreen = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var router_1 = require("@codebuild/glaze/libs/router/router");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var moment = require("moment");
var React = require("react");
var filter_group_1 = require("../../common/components/filter/filter-group");
var customer_partner_select_component_1 = require("../../common/components/list-search/customer-partner-select.component");
var list_date_range_filter_component_1 = require("../../common/components/list-search/list-date-range-filter.component");
var list_search_component_1 = require("../../common/components/list-search/list-search.component");
var user_filter_component_1 = require("../../common/components/list-search/user-filter.component");
var pagination_list_control_1 = require("../../common/components/repository-list/controls/pagination-list-control");
var pagination_control_1 = require("../../common/components/repository-list/controls/pagination.control");
var list_empty_1 = require("../../common/components/repository-list/empty/list-empty");
var list_loader_1 = require("../../common/components/repository-list/loader/list-loader");
var repository_list_1 = require("../../common/components/repository-list/repository-list");
var currency_parser_1 = require("../../common/libs/currency-parser");
var total_box_1 = require("../../components/customer-partner-list/total-box");
var report_download_1 = require("../../components/report-download");
var CustomerPartnerReportScreen = (function (_super) {
    __extends(CustomerPartnerReportScreen, _super);
    function CustomerPartnerReportScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                limit: 20,
                page: 0,
                query: '',
                sort: ''
            },
        });
        _this.state = {
            total: 0,
            statistics: null,
            statisticsLoading: false,
        };
        return _this;
    }
    CustomerPartnerReportScreen.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.getSupplierPrices({ from: moment().add(-3, 'month').format('YYYY-MM-DD') })];
                    case 1:
                        _a.sent();
                        this.control.query$.subscribe(function (query) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                            return [2, this.getSupplierPrices(query)];
                        }); }); });
                        return [2];
                }
            });
        });
    };
    CustomerPartnerReportScreen.prototype.getSupplierPrices = function (query) {
        var _a, _b, _c, _d, _e, _f;
        if (query === void 0) { query = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var parsedQuery, response, compareValues, statistics, e_1;
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        _g.trys.push([0, 2, 3, 4]);
                        this.setState({ statisticsLoading: true });
                        parsedQuery = router_1.Router.encodeQuery(query, true);
                        return [4, this.repository.get("/admin/customer-partner-report/supplier-price".concat(parsedQuery))];
                    case 1:
                        response = (_g.sent());
                        compareValues = this.calculateComparisonValues(response);
                        statistics = {
                            profit: {
                                value: (_b = (_a = response === null || response === void 0 ? void 0 : response.currentYearStatistics) === null || _a === void 0 ? void 0 : _a.profit) !== null && _b !== void 0 ? _b : 0,
                                comparedToPrev: compareValues.profit
                            },
                            cost: {
                                value: (_d = (_c = response === null || response === void 0 ? void 0 : response.currentYearStatistics) === null || _c === void 0 ? void 0 : _c.cost) !== null && _d !== void 0 ? _d : 0,
                                comparedToPrev: compareValues.cost
                            },
                            revenue: {
                                value: (_f = (_e = response === null || response === void 0 ? void 0 : response.currentYearStatistics) === null || _e === void 0 ? void 0 : _e.revenue) !== null && _f !== void 0 ? _f : 0,
                                comparedToPrev: compareValues.revenue
                            },
                        };
                        this.setState({ statistics: statistics });
                        return [3, 4];
                    case 2:
                        e_1 = _g.sent();
                        console.log(e_1);
                        return [3, 4];
                    case 3:
                        this.setState({ statisticsLoading: false });
                        return [7];
                    case 4: return [2];
                }
            });
        });
    };
    CustomerPartnerReportScreen.prototype.calculateComparisonValues = function (_a) {
        var currentYearStatistics = _a.currentYearStatistics, previousYearStatistics = _a.previousYearStatistics;
        var profitDiff = currentYearStatistics.profit - previousYearStatistics.profit;
        var revenueDiff = currentYearStatistics.revenue - previousYearStatistics.revenue;
        var costDiff = currentYearStatistics.cost - previousYearStatistics.cost;
        return {
            profit: {
                value: profitDiff,
                isPositive: profitDiff > 0,
            },
            revenue: {
                value: revenueDiff,
                isPositive: revenueDiff > 0,
            },
            cost: {
                value: costDiff,
                isPositive: costDiff > 0,
            },
        };
    };
    CustomerPartnerReportScreen.prototype.render = function () {
        var _this = this;
        var _a, _b, _c;
        return React.createElement("div", { className: 'EnrichedCustomerPartnerReportScreen ListScreen' },
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-15 " },
                    React.createElement("div", { className: 'display-flex align-items-bottom mb-8' },
                        React.createElement("h2", null,
                            (0, trans_1.trans)('customer-partner-report.list.main.title'),
                            " ",
                            React.createElement("span", { className: 'fs-14' },
                                "(",
                                this.state.total,
                                ")")))),
                React.createElement("div", { className: "col-9" },
                    React.createElement("div", { className: 'display-flex align-items-center justify-content-end' },
                        React.createElement(report_download_1.ReportDownload, { url: '/admin/customer-partner-report/download', control: this.control }))),
                React.createElement("div", { className: 'col-15' }),
                React.createElement("div", { className: "col-9" },
                    React.createElement(list_search_component_1.ListSearchComponent, { "control$": this.control }))),
            React.createElement("div", { className: "justify-content-between align-items-center row pt-5" },
                React.createElement("div", { className: "col-lg-8 col-md-12 col-24 mb-3" },
                    React.createElement(total_box_1.TotalBox, { loading: this.state.statisticsLoading, data: (_a = this.state.statistics) === null || _a === void 0 ? void 0 : _a.cost, text: (0, trans_1.trans)('customer-partner-report.total-cost') })),
                React.createElement("div", { className: "col-lg-7 col-md-12 col-24 mb-3" },
                    React.createElement(total_box_1.TotalBox, { loading: this.state.statisticsLoading, data: (_b = this.state.statistics) === null || _b === void 0 ? void 0 : _b.profit, text: (0, trans_1.trans)('customer-partner-report.total-profit') })),
                React.createElement("div", { className: "col-lg-8 col-md-12 col-24 mb-3" },
                    React.createElement(total_box_1.TotalBox, { loading: this.state.statisticsLoading, data: (_c = this.state.statistics) === null || _c === void 0 ? void 0 : _c.revenue, text: (0, trans_1.trans)('customer-partner-report.total-revenue') }))),
            React.createElement("div", { className: "row pb-4" },
                React.createElement(filter_group_1.FilterGroup, { renderFilters: React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-8" },
                            React.createElement(customer_partner_select_component_1.CustomerPartnerSelectComponent, { label: (0, trans_1.trans)('order-sales-report.filter.customer-partner'), property: 'customerPartner', "control$": this.control })),
                        React.createElement("div", { className: "col-8" },
                            React.createElement(user_filter_component_1.UsersFilterComponent, { label: (0, trans_1.trans)('order-sales-report.filter.salesManager'), property: 'salesManager', "control$": this.control })),
                        React.createElement("div", { className: "col-8" },
                            React.createElement(list_date_range_filter_component_1.ListDateRangeFilterComponent, { startDate: moment().add(-3, 'month').format('YYYY-MM-DD'), "control$": this.control }))) })),
            React.createElement(repository_list_1.RepositoryList, { control: this.control, hideItemsWhileLoading: true, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, onChange: function (ctx) { return _this.handleListChange(ctx); }, repository: repository_1.Repository.use('default'), endpoint: '/admin/customer-partner-report', renderItemsWrapper: function (ctx) { return _this.renderTable(ctx); }, renderItem: function (ctx, item) { return _this.renderItem(ctx, item); }, renderEmpty: function () { return (React.createElement(list_empty_1.ListEmpty, null)); }, renderLoading: function () { return (React.createElement(list_loader_1.ListLoader, null)); } }),
            React.createElement(pagination_control_1.PaginationControl, { "control$": this.control, limitOptions: [
                    { name: 20, value: 20 },
                    { name: 50, value: 50 },
                    { name: 100, value: 100 },
                    { name: 500, value: 500 },
                ] }));
    };
    CustomerPartnerReportScreen.prototype.renderTable = function (ctx) {
        var _this = this;
        return React.createElement(table_1.Table, { className: 'table-basic', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return ctx.renderItems(ctx); } });
    };
    CustomerPartnerReportScreen.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 11 }, this.setHeaderCell('company'), { enableSort: false })),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 9 }, this.setHeaderCell('seller'), { enableSort: false })),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 11 }, this.setHeaderCell('name'), { enableSort: false })),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 11 }, this.setHeaderCell('phone'), { enableSort: false })),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 16 }, this.setHeaderCell('email'), { enableSort: false })),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('cost'), { enableSort: false })),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('profit'), { enableSort: false })),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('revenue'), { enableSort: false })),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('past-3-month'), { enableSort: false })));
    };
    CustomerPartnerReportScreen.prototype.getPriceRevenueBreakDown = function (item) {
        var _a, _b;
        var cost = (_a = item === null || item === void 0 ? void 0 : item.supplierPrices) !== null && _a !== void 0 ? _a : 0;
        var revenue = (_b = item === null || item === void 0 ? void 0 : item.sellingPrice) !== null && _b !== void 0 ? _b : 0;
        var profit = cost === 0 ? 0 : revenue - cost;
        var past3Month = item === null || item === void 0 ? void 0 : item.past3Month;
        return {
            cost: (0, currency_parser_1.hufFormat)(cost),
            revenue: (0, currency_parser_1.hufFormat)(revenue),
            profit: (0, currency_parser_1.hufFormat)(profit),
            past3Month: (0, currency_parser_1.hufFormat)(past3Month),
        };
    };
    CustomerPartnerReportScreen.prototype.renderItem = function (ctx, item) {
        var _a, _b, _c, _d, _e, _f, _g;
        var sellerData = (_a = item === null || item === void 0 ? void 0 : item.contactDatas) === null || _a === void 0 ? void 0 : _a[0];
        var company = (_b = item === null || item === void 0 ? void 0 : item.brandName) !== null && _b !== void 0 ? _b : '-';
        var seller = "".concat((_c = sellerData === null || sellerData === void 0 ? void 0 : sellerData.lastName) !== null && _c !== void 0 ? _c : '', " ").concat((_d = sellerData === null || sellerData === void 0 ? void 0 : sellerData.firstName) !== null && _d !== void 0 ? _d : '');
        var name = (_e = item === null || item === void 0 ? void 0 : item.name) !== null && _e !== void 0 ? _e : '-';
        var phone = (_f = sellerData === null || sellerData === void 0 ? void 0 : sellerData.phone) !== null && _f !== void 0 ? _f : '-';
        var email = (_g = sellerData === null || sellerData === void 0 ? void 0 : sellerData.email) !== null && _g !== void 0 ? _g : '-';
        var _h = this.getPriceRevenueBreakDown(item), cost = _h.cost, profit = _h.profit, revenue = _h.revenue, past3Month = _h.past3Month;
        return React.createElement(table_body_row_1.TableBodyRow, { key: item._id },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 11 },
                React.createElement("span", null, company)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 9 },
                React.createElement("span", null, seller)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 11 },
                React.createElement("span", null, name)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 11 },
                React.createElement("span", null, phone)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 16 },
                React.createElement("span", null, email)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement("span", null, cost)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement("span", null, profit)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement("span", null, revenue)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement("span", null, past3Month)));
    };
    CustomerPartnerReportScreen.prototype.setHeaderCell = function (prop, enableSort) {
        var _this = this;
        if (enableSort === void 0) { enableSort = true; }
        return {
            sortValue: this.getSortValue(prop),
            onSort: function (property, value) { return _this.handleSort(property, value); },
            enableSort: enableSort,
            title: (0, trans_1.trans)("customer-partner-report.list.table.header.".concat(prop)),
            property: prop
        };
    };
    CustomerPartnerReportScreen.prototype.handleListChange = function (ctx) {
        var _a, _b;
        var total = (_a = ctx.state) === null || _a === void 0 ? void 0 : _a.total;
        this.setState({ total: (_b = ctx.state) === null || _b === void 0 ? void 0 : _b.total });
        if (this.props.totalChange) {
            this.props.totalChange(total);
        }
    };
    CustomerPartnerReportScreen.prototype.handleSort = function (property, value) {
        this.control.sort(property, value);
    };
    CustomerPartnerReportScreen.prototype.getSortValue = function (property) {
        var _a;
        return ((_a = this.control.data.sort) === null || _a === void 0 ? void 0 : _a[property]) || 0;
    };
    return CustomerPartnerReportScreen;
}(abstract_component_1.AbstractComponent));
exports.CustomerPartnerReportScreen = CustomerPartnerReportScreen;
