module.exports = {
    'invoice.lang.en': 'Angol',
    'invoice.lang.hu': 'Magyar',
    'invoice.lang.de': 'Német',
    'invoice.lang.it': 'Olasz',
    'invoice.lang.fr': 'Francia',
    'invoice.lang.ro': 'Román',
    'invoice.lang.sk': 'Szlovák',
    'invoice.lang.hr': 'Horvát',
    'customer-partner.list.title': 'Vevő cégek',
    'customer-partner.title': 'Vevő cég',
    'customer-partner.list.button-create.title': 'Új cég létrehozása',
    'customer-partner.update.save-box.button.enable-edit': 'Cég szerkesztése',
    'customer-partner.update.save-box.button.save': 'Mentés',
    'customer-partner.table.header.name': 'Cégnév',
    'customer-partner.table.header.lastOrder': 'Utolsó rendelés',
    'customer-partner.table.header.orderSummary': 'Összes rendelés',
    'customer-partner.table.header.commitment': 'Forgalmi vállalás',
    'customer-partner.table.header.status': 'Státusz',
    'customer-partner.table.header.createdAt': 'Létrehozva',
    'customer-partner.status.active': 'Aktív',
    'customer-partner.status.inactive': 'Inaktív',
    'customer-partner.create.save-box.button.save': 'Létrehozás',
    'customer-partner.update.subtitle.basic-data.coupon': 'Kupon',
    'customer-partner.update.subtitle.basic-data.company-data': 'Cégadatok',
    'customer-partner.update.subtitle.basic-data.address': 'Székhely',
    'customer-partner.update.subtitle.basic-data.other': 'Egyéb adatok',
    'customer-partner.basic-data.form.field.name.coupon': 'Kupon',
    'customer-partner.basic-data.form.field.name.discount': 'Vevő Kedvezmény',
    'customer-partner.basic-data.form.field.name.commission': 'Jutalék',
    'customer-partner.basic-data.form.field.name.no-discount-set': 'Nincs még vevőpartner kedvezmény beállítva',
    'customer-partner.basic-data.form.field.name.email': 'Számlaértesítő email',
    'customer-partner.basic-data.form.field.name.coupon-general': 'Vevőpartner kedvezményes kupon generálás',
    'customer-partner.basic-data.form.field.name.label': 'Cégnév',
    'customer-partner.basic-data.form.field.name.link': 'Affiliate link',
    'customer-partner.basic-data.form.field.brandName.label': 'Márkanév',
    'customer-partner.basic-data.form.field.operatingPermitNumber.label': 'Enegedélyszám',
    'customer-partner.basic-data.form.field.taxNumber.label': 'Adószám',
    'customer-partner.basic-data.form.field.partnerDiscountApplicable.label': 'Vevő partner árral összevonható a kedvezmény',
    'customer-partner.basic-data.form.field.debitCardPayment.label': 'Bankkártyával fizet',
    'customer-partner.basic-data.form.field.invoiceLanguage.label': 'Számlázási nyelv',
    'customer-partner.basic-data.form.field.euTaxNumber.label': 'EU adószám',
    'customer-partner.basic-data.form.field.businessClerk.label': 'Értékesítő',
    'customer-partner.basic-data.form.field.zipCode.label': 'Irányítószám',
    'customer-partner.basic-data.form.field.country.label': 'Ország',
    'customer-partner.basic-data.form.field.city.label': 'Város',
    'customer-partner.basic-data.form.field.address.label': 'Cím',
    'customer-partner.basic-data.form.field.comment.label': 'Megjegyzés',
    'customer-partner.basic-data.form.field.bankAccountNumber.label': 'Bankszámlaszám',
    'customer-partner.basic-data.form.field.paymentDueDate.label': 'Fizetési határidő',
    'customer-partner.basic-data.form.field.billingType.label': 'Számla típusa',
    'customer-partner.basic-data.form.field.documentOperatingPermit.label': 'Működési engedély',
    'customer-partner.basic-data.form.field.documentCopyOfSignatureTitle.label': 'Aláírási címpéldány',
    'customer-partner.commitment-box.form.field.commitment.label': 'Forgalmi vállalás',
    'customer-partner.commitment-box.form.field.fact.label': 'Tény forgalom',
    'customer-partner.credit-box.form.field.credit.label': 'Hitelkeret',
    'customer-partner.credit-box.form.field.debt.label': 'Tartozás',
    'customer-partner.credit-box.form.field.channels.label': 'Partner csatorna',
    'customer-partner.credit-box.form.field.generalPriceModifier.label': 'Partner kedvezmény',
    'customer-partner.credit-box.form.field.invoiceNotificationEmail.label': 'Számlaértesítő email',
    'customer-partner.credit-box.unpaid-orders.table.header.identifier': 'm.azon.',
    'customer-partner.credit-box.unpaid-orders.table.header.currentTotalValue': 'összeg',
    'customer-partner.credit-box.unpaid-orders.table.header.paymentDeadline': 'fiz.határidó',
    'customer-partner.credit-box.unpaid-orders.table.header.invoice': 'számla',
    'address.table.header.operatingPermitNumber': 'Engedélyszám',
    'address.table.header.name': 'Elnevezés',
    'address.table.header.zipCode': 'IRSZ',
    'address.table.header.country': 'Ország',
    'address.table.header.city': 'Város',
    'address.table.header.address': 'Cím',
    'address.table.header.comment': 'Megjegyzés',
    'address.form.field.name.label': 'Elnevezés',
    'address.form.field.country.label': 'Ország',
    'address.form.field.zipCode.label': 'IRSZ',
    'address.form.field.city.label': 'Város',
    'address.form.field.address.label': 'Cím',
    'address.form.field.comment.label': 'Megjegyzés',
    'contact.table.header.name': 'Név',
    'contact.table.header.email': 'E-mail',
    'contact.table.header.phone': 'Telefonszám',
    'contact.table.header.position': 'Pozíció ',
    'contact.table.header.comment': 'Megjegyzés ',
    'contact.form.field.lastName.label': 'Vezetéknév',
    'contact.form.field.firstName.label': 'Keresztnév',
    'contact.form.field.email.label': 'E-mail',
    'contact.form.field.phone.label': 'Telefonszám',
    'contact.form.field.position.label': 'Pozíció ',
    'contact.form.field.comment.label': 'Megjegyzés ',
    'connected-partner.table.header.name': 'Cégnév',
    'connected-partner.table.header.debit': 'Tartozás',
    'connected-partner.table.header.bill': 'Számla',
    'connected-partner.table.header.status': 'Státusz',
    'connected-partner.form.field.name.label': 'Cég',
    'owner.table.header.name': 'Név',
    'owner.table.header.email': 'E-mail',
    'owner.table.header.status': 'Státusz',
    'owner.table.header.createdAt': 'Létrehozva',
    'owner.form.field.user.label': 'Felhasználó',
    'contract.table.header.identifier': 'Azonosító',
    'contract.table.header.type': 'Típus',
    'contract.table.header.date': 'Időtartam',
    'contract.table.header.contact': 'Kapcsolattartó',
    'contract.table.header.files': 'Fájl',
    'contract.table.header.description': 'Leírás',
    'contract.form.field.identifier.label': 'Azonosító',
    'contract.form.field.type.label': 'Típus',
    'contract.form.field.validFrom.label': 'Időtartam',
    'contract.form.field.validTo.label': '',
    'contract.form.field.contact.label': 'Kapcsolattartó',
    'contract.form.field.files.label': 'Fájl',
    'contract.form.field.description.label': 'Leírás',
    'chart.legend.fact': 'Tény adat',
    'chart.legend.commission': 'Járulék adat',
    'chart.legend.commitment': 'Forgalmi vállalás',
    'customer-partner.update.success.toast': 'Sikeresen módosítás',
    'customer-partner.create.success.toast': 'Sikeresen létrehozás',
    'customer-partner.status-update.success.toast': 'Sikeresen státusz módosítás',
};
