"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.B2bStockIssuingListScreen = void 0;
var random_id_1 = require("@codebuild/glaze/libs/libs/random-id");
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var moment = require("moment");
var React = require("react");
var operators_1 = require("rxjs/operators");
var list_search_component_1 = require("../../common/components/list-search/list-search.component");
var pagination_list_control_1 = require("../../common/components/repository-list/controls/pagination-list-control");
var pagination_control_1 = require("../../common/components/repository-list/controls/pagination.control");
var list_empty_1 = require("../../common/components/repository-list/empty/list-empty");
var list_loader_1 = require("../../common/components/repository-list/loader/list-loader");
var repository_list_1 = require("../../common/components/repository-list/repository-list");
var permissions_1 = require("../../common/libs/permissions/permissions");
var clickable_row_1 = require("../../components/clickable-row");
var form_1 = require("../../components/form/form");
var lazy_user_1 = require("../../components/lazy-user");
var badge_1 = require("../../components/libs/badge/badge");
var permission_wrapper_1 = require("../../components/permission-wrapper");
var B2bStockIssuingListScreen = (function (_super) {
    __extends(B2bStockIssuingListScreen, _super);
    function B2bStockIssuingListScreen() {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                limit: 20,
                page: (_c = (_b = (_a = _this.props.route) === null || _a === void 0 ? void 0 : _a.query) === null || _b === void 0 ? void 0 : _b._page) !== null && _c !== void 0 ? _c : 0,
                query: (_f = (_e = (_d = _this.props.route) === null || _d === void 0 ? void 0 : _d.query) === null || _e === void 0 ? void 0 : _e.query) !== null && _f !== void 0 ? _f : '',
                sort: (_h = (_g = _this.props.route) === null || _g === void 0 ? void 0 : _g.sort) !== null && _h !== void 0 ? _h : { createdAt: -1 }
            },
        });
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                customerPartner: new field_1.Field({
                    name: 'customerPartner',
                    id: 'customerPartner',
                    label: 'Vevőcég',
                    value: ((_k = (_j = _this.control) === null || _j === void 0 ? void 0 : _j.getQuery()) === null || _k === void 0 ? void 0 : _k.customerPartner) || [],
                    optionsEndpoint: '/b2b/customer-partner?sort[name]=1&_sort[name]=1&isActive=true',
                    optionsMap: function (response) { var _a; return (_a = response === null || response === void 0 ? void 0 : response.items) === null || _a === void 0 ? void 0 : _a.map(function (o) { return ({ name: o.name, value: o._id, key: o._id }); }); },
                    loadOptionsAfterMount: true,
                }),
            }
        });
        _this.state = {
            isFilterOpen: true,
            total: 0,
        };
        return _this;
    }
    B2bStockIssuingListScreen.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.form.fields.customerPartner.$value.pipe((0, operators_1.debounceTime)(450)).subscribe(function (v) { return _this.handleChange('customerPartner', v); });
                return [2];
            });
        });
    };
    B2bStockIssuingListScreen.prototype.handleChange = function (property, value) {
        this.control.set(property, value);
        this.control.page(0);
    };
    B2bStockIssuingListScreen.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: 'StockIssuingListScreen' },
            React.createElement("div", { className: 'row' },
                React.createElement("div", { className: 'col-15' },
                    React.createElement("div", { className: 'display-flex align-items-bottom mb-8' },
                        React.createElement("h2", null, (0, trans_1.trans)('stock-issuing.list.main.title')),
                        React.createElement("div", { className: 'ml-6' },
                            React.createElement(permission_wrapper_1.PermissionWrapper, { permissions: permissions_1.PERMISSIONS.STORAGE.stockIssue.create },
                                React.createElement(button_1.Button, { onClick: function () { return router_provider_1.RouterProvider.goTo('/stock-issuing/create'); }, title: (0, trans_1.trans)('stock-issuing.list.create.button'), iconLeft: 'fal fa-plus', className: 'button-primary-outline button-size-normal' }))))),
                React.createElement("div", { className: 'col-9' },
                    React.createElement(list_search_component_1.ListSearchComponent, { "control$": this.control }))),
            React.createElement("div", { className: 'row mb-7' },
                React.createElement("div", { className: 'col' },
                    React.createElement("div", { className: 'filter-container' },
                        React.createElement("div", null,
                            React.createElement("div", { className: 'row justify-content-between align-items-center px-2' },
                                React.createElement("small", { className: 'fw-600' }, "SZ\u0170R\u00C9SEK"),
                                React.createElement("div", { onClick: function () { return _this.onFilterToggle(); } },
                                    React.createElement("i", { className: "far ".concat(this.state.isFilterOpen ? 'fa-chevron-up' : 'fa-chevron-down') })))),
                        this.state.isFilterOpen && React.createElement("div", null,
                            React.createElement(form_1.Form, { className: 'row mx-0' },
                                React.createElement(form_control_1.FormControl, { field: this.form.fields.customerPartner, type: select_input_form_control_type_1.SelectInputFormControlType },
                                    React.createElement(select_input_1.SelectInput, { unclearable: false, multiple: true, tabIndex: 2, searchable: true, className: 'select-input-basic col-12 pl-0' }))))))),
            React.createElement(repository_list_1.RepositoryList, { control: this.control, hideItemsWhileLoading: true, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, onChange: function (ctx) { return _this.handleListChange(ctx); }, repository: repository_1.Repository.use('default'), endpoint: '/b2b/stock-issuing', renderItemsWrapper: function (ctx) { return _this.renderTable(ctx); }, renderItem: function (ctx, item) { return _this.renderItem(ctx, item); }, renderEmpty: function () { return (React.createElement(list_empty_1.ListEmpty, null)); }, renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, null)); } }),
            React.createElement(pagination_control_1.PaginationControl, { "control$": this.control, limitOptions: [
                    { name: 20, value: 20 },
                    { name: 50, value: 50 },
                    { name: 100, value: 100 },
                    { name: 500, value: 500 },
                ] }));
    };
    B2bStockIssuingListScreen.prototype.renderTable = function (ctx) {
        var _this = this;
        return React.createElement(table_1.Table, { className: 'table-basic', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return ctx.renderItems(ctx); } });
    };
    B2bStockIssuingListScreen.prototype.renderItem = function (ctx, item) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        console.count(item._id);
        var totalAmount = item.plan.reduce(function (acc, i) { return acc + i.amount; }, 0);
        var name = !!((_a = item.deliveryData) === null || _a === void 0 ? void 0 : _a.fullName) ? (_b = item.deliveryData) === null || _b === void 0 ? void 0 : _b.fullName : (_c = item.__meta) === null || _c === void 0 ? void 0 : _c.customerPartner;
        var address = (!!((_d = item.deliveryData) === null || _d === void 0 ? void 0 : _d.zipCode) && !!((_e = item.deliveryData) === null || _e === void 0 ? void 0 : _e.city) && !!((_f = item.deliveryData) === null || _f === void 0 ? void 0 : _f.address))
            ? "".concat((_g = item.deliveryData) === null || _g === void 0 ? void 0 : _g.zipCode, " ").concat((_h = item.deliveryData) === null || _h === void 0 ? void 0 : _h.city, ", ").concat((_j = item.deliveryData) === null || _j === void 0 ? void 0 : _j.address)
            : '-';
        return React.createElement(clickable_row_1.ClickableRow, { key: (0, random_id_1.randomId)(), href: "/stock-issuing/".concat(item === null || item === void 0 ? void 0 : item._id) },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                React.createElement("p", null, item.identifier)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                React.createElement("p", null, name)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 25 },
                React.createElement("p", null, address)),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement("p", null,
                    totalAmount || 0,
                    " db")),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement(badge_1.Badge, { key: 0, title: (0, trans_1.trans)("b2b-stock-issuing.status.badge.".concat((item === null || item === void 0 ? void 0 : item.status) || 'draft')), type: (item === null || item === void 0 ? void 0 : item.status) === 'executed' ? 'success' : 'neutral' })),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                React.createElement(lazy_user_1.LazyUser, { _id: item.creator })),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement("p", null, moment(item.createdAt).format('YYYY.MM.DD'))));
    };
    B2bStockIssuingListScreen.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('identifier'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('customerPartner'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 25 }, this.setHeaderCell('delivery-address'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('amount'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('status'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('creator'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('createdAt'))));
    };
    B2bStockIssuingListScreen.prototype.setHeaderCell = function (p, enableSort) {
        var _this = this;
        if (enableSort === void 0) { enableSort = true; }
        return {
            sortValue: this.getSortValue(p),
            onSort: function (property, value) { return _this.handleSort(property, value); },
            enableSort: enableSort,
            title: (0, trans_1.trans)("b2b-stock-issuing.table.header.".concat(p)),
            property: p
        };
    };
    B2bStockIssuingListScreen.prototype.handleSort = function (property, value) {
        this.control.sort(property, value);
    };
    B2bStockIssuingListScreen.prototype.getSortValue = function (property) {
        var _a;
        return ((_a = this.control.data.sort) === null || _a === void 0 ? void 0 : _a[property]) || 0;
    };
    B2bStockIssuingListScreen.prototype.handleListChange = function (ctx) {
        var _a, _b;
        var total = (_a = ctx.state) === null || _a === void 0 ? void 0 : _a.total;
        this.setState({ total: (_b = ctx.state) === null || _b === void 0 ? void 0 : _b.total, openedRows: [] });
        if (this.props.totalChange) {
            this.props.totalChange(total);
        }
    };
    B2bStockIssuingListScreen.prototype.onFilterToggle = function () {
        this.setState({ isFilterOpen: !this.state.isFilterOpen });
    };
    return B2bStockIssuingListScreen;
}(abstract_component_1.AbstractComponent));
exports.B2bStockIssuingListScreen = B2bStockIssuingListScreen;
