"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StockRotationReportScreen = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var checkbox_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/checkbox.form-control-type");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var checkbox_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/checkbox-input");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var lodash_1 = require("lodash");
var React = require("react");
var operators_1 = require("rxjs/operators");
var list_search_component_1 = require("../../common/components/list-search/list-search.component");
var pagination_list_control_1 = require("../../common/components/repository-list/controls/pagination-list-control");
var pagination_control_1 = require("../../common/components/repository-list/controls/pagination.control");
var list_empty_1 = require("../../common/components/repository-list/empty/list-empty");
var list_loader_1 = require("../../common/components/repository-list/loader/list-loader");
var repository_list_1 = require("../../common/components/repository-list/repository-list");
var currency_parser_1 = require("../../common/libs/currency-parser");
var form_1 = require("../../components/form/form");
require("./styles.scss");
var report_download_1 = require("../../components/report-download");
var round_1 = require("../../libs/round");
var color_filter_1 = require("./components/color-filter");
var StockRotationReportScreen = (function (_super) {
    __extends(StockRotationReportScreen, _super);
    function StockRotationReportScreen() {
        var _this = this;
        var _a, _b;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                limit: 20,
                page: 0,
                query: '',
                sort: ''
            },
        });
        _this.state = {
            total: 0,
            isFilterOpen: true
        };
        _this.filterForm = new form_builder_1.FormBuilder({
            fields: {
                supplierPartner: new field_1.Field({
                    name: 'supplierPartner',
                    label: (0, trans_1.trans)('b2b-stock-history.filter.form.label.supplierPartner'),
                    value: ((_b = (_a = _this.control) === null || _a === void 0 ? void 0 : _a.getQuery()) === null || _b === void 0 ? void 0 : _b.country) || null,
                    optionsEndpoint: '/b2b/supplier-partner',
                    optionsMap: function (response) {
                        var _a;
                        return (_a = response === null || response === void 0 ? void 0 : response.items) === null || _a === void 0 ? void 0 : _a.map(function (item) { return ({
                            name: "".concat(item.name, " (").concat(item.brandName, ")"),
                            value: item._id,
                            key: item._id,
                        }); });
                    },
                    loadOptionsAfterMount: true,
                }),
                showAll: new field_1.Field({
                    placeholder: '',
                    value: false,
                    name: 'showAll',
                    label: 'Inaktív termékeket is mutasd',
                    validators: []
                })
            }
        });
        return _this;
    }
    StockRotationReportScreen.prototype.componentDidMount = function () {
        var _this = this;
        this.filterForm.fields.supplierPartner.$value.pipe((0, operators_1.debounceTime)(450)).subscribe(function (v) { return _this.handleChange('supplierPartner', v); });
        this.filterForm.fields.showAll.$value.pipe((0, operators_1.debounceTime)(450)).subscribe(function (v) { return _this.handleChange('showAll', v); });
    };
    StockRotationReportScreen.prototype.handleChange = function (property, value) {
        var currentValue = this.control.getQuery()[property];
        if (!!(0, lodash_1.isEqual)(currentValue, value)) {
            return;
        }
        this.control.set(property, value);
        this.control.page(0);
    };
    StockRotationReportScreen.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: 'CustomerPartnerCouponReportScreen ListScreen' },
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-15 " },
                    React.createElement("div", { className: 'display-flex align-items-bottom mb-8' },
                        React.createElement("h2", null, (0, trans_1.trans)('stock-rotation-report.list.main.title')))),
                React.createElement("div", { className: "col-9" },
                    React.createElement("div", { className: 'display-flex align-items-center justify-content-end' },
                        React.createElement(report_download_1.ReportDownload, { url: '/admin/stock-rotation-report/download', control: this.control }))),
                React.createElement("div", { className: 'col-15' }),
                React.createElement("div", { className: "col-9 display-flex align-items-center justify-content-end" },
                    React.createElement(list_search_component_1.ListSearchComponent, { "control$": this.control })),
                React.createElement("div", { className: 'col-24 row mt-4 mb-7' },
                    React.createElement("div", { className: 'col' },
                        React.createElement("div", { className: 'filter-wrapper' },
                            React.createElement("div", { className: 'row justify-content-between align-items-center px-2' },
                                React.createElement("p", { className: 'typo-small fw-600' }, (0, trans_1.trans)('b2b-stock-history.filters.title')),
                                React.createElement("div", { onClick: function () { return _this.setState({ isFilterOpen: !_this.state.isFilterOpen }); } },
                                    React.createElement("i", { className: "far ".concat(this.state.isFilterOpen ? 'fa-chevron-up' : 'fa-chevron-down') }))),
                            this.state.isFilterOpen && React.createElement(form_1.Form, null,
                                React.createElement("div", { className: "row" },
                                    React.createElement("div", { className: 'col' },
                                        React.createElement(color_filter_1.ColorFilter, { "control$": this.control, label: 'Készlet szűrés' })),
                                    React.createElement("div", { className: 'col' },
                                        React.createElement("div", { className: "flex-fill" },
                                            React.createElement(form_control_1.FormControl, { field: this.filterForm.fields.supplierPartner, type: select_input_form_control_type_1.SelectInputFormControlType },
                                                React.createElement(select_input_1.SelectInput, { searchable: true, unclearable: false, clearAll: true, multiple: true, tabIndex: 1, className: 'select-input-basic pl-0' }))))),
                                React.createElement("div", { className: "row" },
                                    React.createElement(form_control_1.FormControl, { field: this.filterForm.fields.showAll, type: checkbox_form_control_type_1.CheckboxFormControlType },
                                        React.createElement(checkbox_input_1.CheckboxInput, { className: 'checkbox-input-basic' })))))))),
            React.createElement(repository_list_1.RepositoryList, { control: this.control, hideItemsWhileLoading: true, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, onChange: function (ctx) { return _this.handleListChange(ctx); }, repository: repository_1.Repository.use('default'), endpoint: '/admin/stock-rotation-report', renderItemsWrapper: function (ctx) { return _this.renderTable(ctx); }, renderItem: function (ctx, item) { return _this.renderItem(ctx, item); }, renderEmpty: function () { return (React.createElement(list_empty_1.ListEmpty, null)); }, renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, null)); } }),
            React.createElement(pagination_control_1.PaginationControl, { "control$": this.control, limitOptions: [
                    { name: 20, value: 20 },
                    { name: 50, value: 50 },
                    { name: 100, value: 100 },
                    { name: 500, value: 500 },
                ] }));
    };
    StockRotationReportScreen.prototype.renderTable = function (ctx) {
        var _this = this;
        return React.createElement(table_1.Table, { className: 'table-basic', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return ctx.renderItems(ctx); } });
    };
    StockRotationReportScreen.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 15 }, this.setHeaderCell('product'), { enableSort: false })),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 9 }, this.setHeaderCell('available'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('procurement'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 9 }, this.setHeaderCell('rotations.7days.sum'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 9 }, this.setHeaderCell('rotations.14days.sum'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 9 }, this.setHeaderCell('rotations.30days.sum'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 9 }, this.setHeaderCell('rotations.60days.sum'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 9 }, this.setHeaderCell('rotations.90days.sum'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 9 }, this.setHeaderCell('rotations.120days.sum'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 9 }, this.setHeaderCell('rotations.365days.sum'))));
    };
    StockRotationReportScreen.prototype.getRowColor = function (available, thirtyDay) {
        if (available === 0) {
            return 'bgc-red';
        }
        return available < thirtyDay ? 'bgc-yellow' : '';
    };
    StockRotationReportScreen.prototype.renderItem = function (ctx, item) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
        var ean = item.eanCode || '-';
        var sku = item.sku || '-';
        var manufacturer = ((_a = item.manufacturerName) === null || _a === void 0 ? void 0 : _a.hu) || '-';
        var title = ((_b = item.title) === null || _b === void 0 ? void 0 : _b.hu) || '-';
        var vintage = item.vintage || '-';
        var capacity = ((_d = (_c = item.b2cAttributeSearchValues) === null || _c === void 0 ? void 0 : _c.capacity) === null || _d === void 0 ? void 0 : _d[1]) || '-';
        var available = (_e = item === null || item === void 0 ? void 0 : item.available) !== null && _e !== void 0 ? _e : 0;
        var procurement = (_f = item === null || item === void 0 ? void 0 : item.procurement) !== null && _f !== void 0 ? _f : 0;
        var thirtyDay = ((_h = (_g = item === null || item === void 0 ? void 0 : item.rotations) === null || _g === void 0 ? void 0 : _g['30days']) === null || _h === void 0 ? void 0 : _h.sum) || 0;
        var color = this.getRowColor(available, thirtyDay);
        return React.createElement(table_body_row_1.TableBodyRow, { key: item._id, className: color },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 15 },
                React.createElement("div", null,
                    React.createElement("p", null,
                        React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "EAN"),
                        React.createElement("span", null, ean)),
                    React.createElement("p", null,
                        React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "SKU"),
                        React.createElement("span", null, sku)),
                    React.createElement("p", null,
                        manufacturer,
                        " ",
                        title),
                    React.createElement("p", null,
                        vintage,
                        " (",
                        capacity,
                        ")"))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 9 },
                React.createElement("span", null,
                    (0, currency_parser_1.formatNumber)(available),
                    " db")),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                React.createElement("span", null,
                    (0, currency_parser_1.formatNumber)(procurement),
                    " db")),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 9 },
                React.createElement("span", null,
                    (0, currency_parser_1.formatNumber)(((_k = (_j = item === null || item === void 0 ? void 0 : item.rotations) === null || _j === void 0 ? void 0 : _j['7days']) === null || _k === void 0 ? void 0 : _k.sum) || 0),
                    " db")),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 9 },
                React.createElement("span", null,
                    (0, currency_parser_1.formatNumber)(((_m = (_l = item === null || item === void 0 ? void 0 : item.rotations) === null || _l === void 0 ? void 0 : _l['14days']) === null || _m === void 0 ? void 0 : _m.sum) || 0),
                    " db")),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 9 },
                React.createElement("span", null,
                    (0, currency_parser_1.formatNumber)(((_p = (_o = item === null || item === void 0 ? void 0 : item.rotations) === null || _o === void 0 ? void 0 : _o['30days']) === null || _p === void 0 ? void 0 : _p.sum) || 0),
                    " db")),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 9 },
                React.createElement("span", null, this.calculateAverage((_r = (_q = item === null || item === void 0 ? void 0 : item.rotations) === null || _q === void 0 ? void 0 : _q['60days']) === null || _r === void 0 ? void 0 : _r.sum, 60))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 9 },
                React.createElement("span", null, this.calculateAverage((_t = (_s = item === null || item === void 0 ? void 0 : item.rotations) === null || _s === void 0 ? void 0 : _s['90days']) === null || _t === void 0 ? void 0 : _t.sum, 90))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 9 },
                React.createElement("span", null, this.calculateAverage((_v = (_u = item === null || item === void 0 ? void 0 : item.rotations) === null || _u === void 0 ? void 0 : _u['120days']) === null || _v === void 0 ? void 0 : _v.sum, 120))),
            React.createElement(table_body_cell_1.TableBodyCell, { width: 9 },
                React.createElement("span", null, this.calculateAverage((_x = (_w = item === null || item === void 0 ? void 0 : item.rotations) === null || _w === void 0 ? void 0 : _w['365days']) === null || _x === void 0 ? void 0 : _x.sum, 365))));
    };
    StockRotationReportScreen.prototype.calculateAverage = function (value, days) {
        if (!value) {
            return React.createElement("span", null,
                (0, currency_parser_1.formatNumber)(value),
                " db");
        }
        var divider = days / 30;
        var thirtyDayAverage = (0, round_1.round)(value / divider);
        return React.createElement("span", null,
            (0, currency_parser_1.formatNumber)(value),
            " db ",
            React.createElement("span", { className: 'color--neutral-5' },
                "(",
                thirtyDayAverage,
                " db)"));
    };
    StockRotationReportScreen.prototype.setHeaderCell = function (p, enableSort) {
        var _this = this;
        if (enableSort === void 0) { enableSort = true; }
        return {
            sortValue: this.getSortValue(p),
            onSort: function (property, value) { return _this.handleSort(property, value); },
            enableSort: enableSort,
            title: (0, trans_1.trans)("stock-rotation-report.list.table.header.".concat(p)),
            property: p
        };
    };
    StockRotationReportScreen.prototype.handleListChange = function (ctx) {
        var _a, _b;
        var total = (_a = ctx.state) === null || _a === void 0 ? void 0 : _a.total;
        this.setState({ total: (_b = ctx.state) === null || _b === void 0 ? void 0 : _b.total });
        if (this.props.totalChange) {
            this.props.totalChange(total);
        }
    };
    StockRotationReportScreen.prototype.handleSort = function (property, value) {
        this.control.sort(property, value);
    };
    StockRotationReportScreen.prototype.getSortValue = function (property) {
        var _a;
        return ((_a = this.control.data.sort) === null || _a === void 0 ? void 0 : _a[property]) || 0;
    };
    return StockRotationReportScreen;
}(abstract_component_1.AbstractComponent));
exports.StockRotationReportScreen = StockRotationReportScreen;
