"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageUploader = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var lodash_1 = require("lodash");
var React = require("react");
require("./image-uploader.scss");
var ImageUploader = (function (_super) {
    __extends(ImageUploader, _super);
    function ImageUploader() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.upload = repository_1.Repository.use('attachment');
        _this.state = {
            image: _this.props.value || null,
            error: false,
            loading: false
        };
        return _this;
    }
    ImageUploader.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (!(0, lodash_1.isEqual)(prevProps.value, this.props.value)) {
            this.setState({ image: this.props.value });
        }
    };
    ImageUploader.prototype.render = function () {
        var _this = this;
        var ratio = (this.props.width || 1) / (this.props.heigth || 1);
        return React.createElement("div", { className: 'ImageUploader', style: { paddingTop: "calc(100% / ".concat(ratio, ")") } },
            this.renderLoading(),
            this.renderDelete(),
            React.createElement("input", { className: 'UploadInput', type: 'file', disabled: this.props.disabled, accept: "image/png, image/jpeg", ref: function (ref) { return _this.fileInputRef = ref; }, onChange: (function (event) { return _this.uploadFile(event); }), title: (0, trans_1.trans)('image-uploader.input.title') }),
            !this.state.image && this.renderEmpty(),
            !!this.state.image && this.renderImagePreview());
    };
    ImageUploader.prototype.renderEmpty = function () {
        if (!!this.state.image || this.state.loading) {
            return;
        }
        return React.createElement("div", { className: 'ImageEmpty' },
            React.createElement("div", { className: 'display-flex flex-column justify-content-center align-items-center' },
                React.createElement("strong", null, (0, trans_1.trans)('image-uploader.empty.line-1')),
                React.createElement("p", { className: 'color--secondary-dark-3' }, (0, trans_1.trans)('image-uploader.empty.line-2')),
                React.createElement("p", { className: 'color--brand-primary-1 mb-2' },
                    React.createElement("i", { className: 'fal fa-folders' }),
                    (0, trans_1.trans)('image-uploader.empty.line-3')),
                React.createElement("small", { className: 'color--secondary-dark-3' }, (0, trans_1.trans)('image-uploader.enabled-format.title')),
                React.createElement("small", { className: 'color--secondary-dark-3' }, (0, trans_1.trans)('image-uploader.enabled-size.title'))));
    };
    ImageUploader.prototype.renderImagePreview = function () {
        return React.createElement("div", { className: 'ImagePreview', style: { backgroundImage: "url(".concat(this.state.image, ")") } });
    };
    ImageUploader.prototype.renderLoading = function () {
        if (!this.state.loading) {
            return;
        }
        return React.createElement("div", { className: 'UploadLoading' },
            React.createElement("i", { className: "fa fa-spinner-third fa-spin" }));
    };
    ImageUploader.prototype.renderDelete = function () {
        var _this = this;
        if (this.props.disabled) {
            return;
        }
        if (!this.state.image) {
            return;
        }
        return React.createElement("div", { className: 'DeleteButton' },
            React.createElement(button_1.Button, { onClick: function () { return _this.deleteFile(); }, className: 'button-primary-normal button-size-normal', title: React.createElement("i", { className: 'fal fa-trash-alt fa-lg' }) }));
    };
    ImageUploader.prototype.uploadFile = function (event) {
        var _a, _b, _c, _d, _e;
        return __awaiter(this, void 0, void 0, function () {
            var files, file, form, name_1, fName, response, payload, e_1;
            var _this = this;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        if (this.props.disabled) {
                            return [2];
                        }
                        if (this.state.loading) {
                            return [2];
                        }
                        _f.label = 1;
                    case 1:
                        _f.trys.push([1, 3, , 4]);
                        this.setState({ loading: true });
                        files = event.target.files;
                        file = files.item(0);
                        if (!file) {
                            this.setState({ loading: false });
                            return [2];
                        }
                        form = new FormData();
                        name_1 = (this.props.namePrefix
                            ? ((_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.namePrefix) === null || _b === void 0 ? void 0 : _b.call(_a)) || file.name
                            : file.name)
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/\p{Diacritic}/gu, '')
                            .replace(/\./gu, '')
                            .replace(/'/gu, '')
                            .replace(/ /gu, '');
                        fName = /.(png|jpg|jpeg)/.test(name_1)
                            ? name_1
                            : "".concat(name_1, ".png");
                        console.log({ fName: fName, name: name_1 });
                        form.append('data', file);
                        form.append('name', fName);
                        return [4, this.upload.post('/attachment', {
                                data: form
                            })];
                    case 2:
                        response = _f.sent();
                        console.log({ response: response });
                        if (!response) {
                            this.setState({ loading: false });
                            return [2];
                        }
                        payload = ((_d = (_c = response === null || response === void 0 ? void 0 : response.variants) === null || _c === void 0 ? void 0 : _c.find(function (item) { return item.type === (_this.props.targetVariant || 'default'); })) === null || _d === void 0 ? void 0 : _d.payload) || ((_e = response === null || response === void 0 ? void 0 : response.default) === null || _e === void 0 ? void 0 : _e.payload);
                        this.setState({ image: payload, loading: false });
                        if (this.props.onChange) {
                            this.props.onChange(this.state.image);
                        }
                        return [3, 4];
                    case 3:
                        e_1 = _f.sent();
                        this.setState({ loading: false });
                        console.log({ uploadError: e_1 });
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    ImageUploader.prototype.getValue = function () {
        return this.state.image || null;
    };
    ImageUploader.prototype.deleteFile = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.setState({ image: null });
                if (this.props.onChange) {
                    this.props.onChange(this.state.image);
                }
                return [2];
            });
        });
    };
    return ImageUploader;
}(abstract_component_1.AbstractComponent));
exports.ImageUploader = ImageUploader;
